html,
body,
#root {
  height: 100%;
}


.shift-tooltip .MuiTooltip-tooltip{
  max-width: 170px;
  line-height: 1.25;
}